var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { EventsService } from './events.service';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
var AuthService = /** @class */ (function (_super) {
    __extends(AuthService, _super);
    function AuthService(http, router, event, userService) {
        var _this = _super.call(this, event) || this;
        _this.http = http;
        _this.router = router;
        _this.event = event;
        _this.userService = userService;
        _this.loggedIn = false;
        _this.redirectUrl = '';
        _this.setLogin = function (value) {
            _this.loggedIn = value;
        };
        _this.login = function (keys) {
            return _this.http.post(_this.baseUrl + '/login', keys, _this.options).pipe(map(function (res) { return res; }));
        };
        _this.logout = function () {
            if (_this.userService.getUser()) {
                _this.userService.clearUser();
                _this.clearToken();
            }
            window.open(environment.myJourneyUrl + 'logout', '_self');
        };
        _this.requestReset = function (email) {
            return _this.http.post(_this.baseUrl + '/forgotpassword', email, _this.options).pipe(map(function (res) { return res; }));
        };
        _this.testLogin = function () {
            return _this.http.get(_this.baseUrl + '/auth/me', _this.options).pipe(map(function (res) { return res; }));
        };
        _this.acceptTerms = function (userid) {
            return _this.http.post(_this.baseUrl + ("/auth/accepttc/" + userid), {}, _this.options);
        };
        _this.acceptInvite = function (token) {
            return _this.http.post(_this.baseUrl + '/acceptinvite', { token: token }, _this.options).pipe(map(function (res) { return res; }));
        };
        _this.declineCoe = function (token) {
            return _this.http.post(_this.baseUrl + '/declinecoe', { token: token }, _this.options).pipe(map(function (res) { return res; }));
        };
        _this.checkCoe = function (token) {
            return _this.http.post(_this.baseUrl + '/checkcoe', { token: token }, _this.options).pipe(map(function (res) { return res; }));
        };
        _this.sendReset = function (keys) {
            return _this.http.put(_this.baseUrl + '/resetpassword', keys, _this.options).pipe(map(function (res) { return res; }));
        };
        _this.event.on('logOut', function (eventVal) {
            _this.logout();
        });
        return _this;
    }
    AuthService.prototype.requestUrl = function (userId) {
        return this.http.get(environment.baseUrl + "/volunteer/editprofileurl/" + userId, this.options);
    };
    return AuthService;
}(BaseService));
export { AuthService };
