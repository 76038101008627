import { NgModule, InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken<AppConfig>('app-config');

export class AppConfig {
  // readonly froalaLicenseKey = 'pFOFSAGLUd1AVKg1SN=='; // version < 2.8.0
  // readonly froalaLicenseKey = 'pD2A1H2E1B1C3B3lA6F6D5F4A1D3C7A3D5F5gSXSJ1LHAFJVCXCLS=='; // version >= 2.8.0
  readonly froalaLicenseKey = 'pe1G2wF1G1C2C1C6C6C4oCe1ZSc2XHe1Cd1f1KIWCWMJHXCLSwD1D1D1E1F1I4C8B1D6E5=='; // version 3 & 4

}

export let appConfig = new AppConfig();

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: appConfig
  }]
})
export class AppConfigModule {}
