import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { BaseService } from './base.service';
import {IUser} from '../interfaces-classes';
import { Router } from '@angular/router';
import { EventsService  } from './events.service';
import {UserService} from './user.service';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable()
export class AuthService extends BaseService {
  public loggedIn = false;
  public redirectUrl = '';
  constructor(
    private http: HttpClient,
    private router: Router,
    public event: EventsService,
    private userService: UserService
  ) {
    super(event);
    this.event.on('logOut', ( eventVal: any) => {
      this.logout()
    })
  }

  setLogin = (value: boolean) => {
    this.loggedIn = value;
  }

  login = (keys: any): Observable<{user: IUser, token: string}>  => {
    return this.http.post(this.baseUrl + '/login', keys, this.options).pipe(map(res => <any> res))
  }

  logout = () => {
    if (this.userService.getUser()) {
      this.userService.clearUser();
      this.clearToken();
    }
    window.open(environment.myJourneyUrl + 'logout','_self')
  }

  requestReset = (email: string) => {
    return this.http.post(this.baseUrl + '/forgotpassword', email, this.options).pipe(map(res => <any> res))
  }

  testLogin = (): Observable<{user: IUser, token: string}> => {
    return this.http.get(this.baseUrl + '/auth/me', this.options).pipe(map(res => <any> res))
  }

  acceptTerms = (userid: number) => {
    return this.http.post(this.baseUrl + `/auth/accepttc/${userid}`, {}, this.options)
  }

  acceptInvite = (token: string) => {
    return this.http.post(this.baseUrl + '/acceptinvite', {token: token}, this.options).pipe(map(res => <any> res))
  }

  declineCoe = (token: string) => {
    return this.http.post(this.baseUrl + '/declinecoe', {token: token}, this.options).pipe(map(res => <any> res))
  }

  checkCoe = (token: string) => {
    return this.http.post(this.baseUrl + '/checkcoe', {token: token}, this.options).pipe(map(res => <any> res))
  }

  sendReset = (keys: {password: string, url: string, newUser?: boolean }) => {
    return this.http.put(this.baseUrl + '/resetpassword', keys, this.options).pipe(map(res => <IUser> res))
  }

  requestUrl(userId: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/volunteer/editprofileurl/${userId}`, this.options);
  }

}
