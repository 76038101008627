import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EventsService } from './events.service';
import { throwError } from 'rxjs/internal/observable/throwError';
var BaseEncoder = /** @class */ (function () {
    function BaseEncoder() {
    }
    BaseEncoder.prototype.encodeKey = function (k) {
        return encodeURIComponent(k);
    };
    BaseEncoder.prototype.encodeValue = function (v) {
        return encodeURIComponent(v);
    };
    BaseEncoder.prototype.decodeKey = function (k) {
        return decodeURIComponent(k);
    };
    BaseEncoder.prototype.decodeValue = function (v) {
        return decodeURIComponent(v);
    };
    return BaseEncoder;
}());
var BaseService = /** @class */ (function () {
    function BaseService(event) {
        var _this = this;
        this.event = event;
        this.getQueryOptions = function (querys) {
            var params = new HttpParams({ encoder: new BaseEncoder() });
            for (var property in querys) {
                if (querys.hasOwnProperty(property)) {
                    params = params.append(property, querys[property]);
                }
            }
            return { headers: _this.headers, params: params, withCredentials: true };
        };
        this.setToken = function (token) {
            sessionStorage.setItem('token', JSON.stringify(token));
        };
        this.clearToken = function () {
            _this.token = null;
            window.sessionStorage.removeItem('token');
        };
        this.handleError = function (error) {
            if (error.status === 0 || error.status === 401) {
                _this.event.broadcast('logOut', true);
            }
            return throwError(error);
        };
        this.baseUrl = environment.baseUrl;
        this.resourceUrl = environment.resourceBase;
        this.reportUrl = environment.reportUrl;
        this._mocksOn = false;
    }
    Object.defineProperty(BaseService.prototype, "headers", {
        get: function () {
            this.token = JSON.parse(sessionStorage.getItem('token'));
            var temp = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.token ? this.token : ''
            });
            return temp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseService.prototype, "options", {
        get: function () {
            return { headers: this.headers, withCredentials: true };
        },
        enumerable: true,
        configurable: true
    });
    return BaseService;
}());
export { BaseService };
