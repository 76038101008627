// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
import { versions } from './versions';
let ver =  versions ? versions : null;
export const environment = {
production: true,
envname:'UAT',
baseUrl:'https://uateproveapi.advanc-ed.org/workspace-api/v1',
resetUrl:'https://uateproveworkspace.advanc-ed.org/login/confirm-reset/',
  findingsUrl:'http://deveproveapi.advanc-ed.org:8113/workspace-api/v1',
resourceBase:'https://uateproveworkspace.advanc-ed.org',
profileBase:'https://uateproveuserprofile.advanc-ed.org:8113/',
reportUrl:'https://uateproveapi.advanc-ed.org/diagnostics-teams-api/v1',
reportsSharedDriveUri:'https://uateprovereports.advanc-ed.org/reports-diagnostics-teams/',
myJourneyUrl:'https://uatmyjourney.cognia.org/',
showProfile: false,
  version:ver,
  privacyPolicyUrl: 'https://static.cognia.org/privacy-policy.html',
  SEND_TO_WORKSPACE_REPORT: 'sendToWorkspace'
};
