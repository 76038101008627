import { Injectable } from '@angular/core';
import { Router, NavigationStart,NavigationEnd} from '@angular/router';
import { EventsService  } from './events.service';
@Injectable()
export class RouterLoadingService {
  private loggingInByToken: boolean;
  constructor(
    private event: EventsService,
    private router:Router
  ) {
    this.event.on("loggingInByToken", (value:boolean) => {
      this.loggingInByToken = value
    });
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationStart) {
        this.event.broadcast('isLoading',false);
        this.event.broadcast('isNavigating',true);
      }
      if(event instanceof NavigationEnd) {
        if (!this.loggingInByToken) {
          this.event.broadcast('isLoading', false);
          this.event.broadcast('isNavigating', false);
        }
      }
    });
  }
}
