import { Component} from '@angular/core';
import { EventsService } from '../global-services/events.service';

@Component({
  selector: 'overlay-lock',
  template: `
  <div class="overlay-lock"
       [ngClass]="{
        'detail-active': overlayActive,
        'menu-active': menuActive,
        'loading-active': loading,
        'navigating-active': navigating
       }"
  >
    <ng-content></ng-content>
    <div class="panel-overlay">
      <div class="overlay-loader"><i class="fa fa-spinner fa-spin"></i></div>
      <div class="loader-text">Loading...</div>
    </div>`
})
export class OverlayLockComponent {
  overlayActive:boolean = false;
  menuActive:boolean = false;
  loading:boolean = false;
  navigating:boolean = false;
  constructor(
    private event: EventsService
  ) {
    this.event.on('overlayLock', (value:boolean) => {
      this.overlayActive = value;
    });

    this.event.on('isLoading', (value:boolean) => {
      this.loading = value;
    });

    this.event.on('isNavigating', (value:boolean) => {
      this.navigating = value;
    });

    this.event.on('setMenuState', (value:boolean) => {
      this.menuActive = value;
    });
  }
}
