import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedMyMessageService {
  private _showSuccessSubject: Subject<{
    severity: string, detail: string, summary?: string, key?: string
  }> = new Subject();

  showSuccess$: Observable<{
    severity: string, detail: string, summary?: string, key?: string
  }> = this._showSuccessSubject.asObservable();

  private _showInfoSubject: Subject<{
    severity: string, detail: string, summary?: string, key?: string
  }> = new Subject();

  showInfo$: Observable<{
    severity: string, detail: string, summary?: string, key?: string
  }> = this._showInfoSubject.asObservable();

  private _showWarnSubject: Subject<{
    severity: string, detail: string, summary?: string, key?: string
  }> = new Subject();

  showWarn$: Observable<{
    severity: string, detail: string, summary?: string, key?: string
  }> = this._showWarnSubject.asObservable();

  private _showErrorSubject: Subject<{
    severity: string, detail: string, summary?: string, key?: string
  }> = new Subject();

  showError$: Observable<{
    severity: string, detail: string, summary?: string, key?: string
  }> = this._showErrorSubject.asObservable();

  private _showCustomSubject: Subject<{
    severity: string, detail: string, summary?: string, key?: string
  }> = new Subject();

  showCustom$: Observable<{
    severity: string, detail: string, summary?: string, key?: string
  }> = this._showCustomSubject.asObservable();

  constructor() {}

  showSuccess(content: {summary?: string, detail: string} = {
    summary: 'Success',
    detail: ''
  }) {
    this._showSuccessSubject.next({key: 'toast', severity: 'success', summary: content.summary || 'Success', detail: content.detail});
  }

  showInfo(content: {summary?: string, detail: string} = {
    summary: 'Info',
    detail: ''
  }) {
    this._showInfoSubject.next({key: 'toast', severity: 'info', summary: content.summary || 'Info', detail: content.detail});
  }

  showWarn(content: {summary?: string, detail: string} = {
    summary: 'Warning',
    detail: ''
  }) {
    this._showWarnSubject.next({key: 'toast', severity: 'warn', summary: content.summary || 'Warning', detail: content.detail});
  }

  showError(content: {summary?: string, detail: string} = {
    summary: 'Error',
    detail: ''
  }) {
    this._showErrorSubject.next({key: 'toast', severity: 'error', summary: content.summary || 'Error', detail: content.detail});
  }

  showCustom(content: {summary?: string, detail: string} = {
    summary: 'Info',
    detail: ''
  }) {
    this._showCustomSubject.next({key: 'toast', severity: 'info', summary: content.summary || 'Info', detail: content.detail});
  }

}
