import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

export class ValidationService {
  private messages:any;
  constructor(){
    this.messages = {
        'required': 'Required',
        'noSpaces': 'Spaces are invalid characters',
        'invalidEmailAddress': 'Invalid email address',
        'invalidPassword': 'Password must be at least 8 characters, contain a number, and mixed case letters.',
        'invalidDate': 'Invalid date ',
        'notEquivalent': 'Passwords do not match',
        'notUniqueMember':'User is already a member of this workspace',
        'startsWhitepace':"Input can't start with a space",
        'endsWhitepace':"Input can't end with a space",
        'invalidLink':"Invalid Link",
        'startsPeriod':"Name Can't Start with a .",
        'hasInvalidCharacters':"Name Can't contain \\ / : * ? \" \< \> |",
        'bannedNames':'File name contains restricted names',
        'notValidSelection':'Not A Valid Selection',
        'invalidExtension': 'Invalid file extension.'
    };
  }

  addValidation = (key:string,msg:string) => {
    this.messages[key] = msg;
  }

  getValidatorErrorMessage = (key:string) => {
    return this.messages[key];
  }

  emptyStringValidator = (control:FormControl) => {
    return !control.value.includes(' ') ? null : {
      noSpaces: false
    }
  }

  fileNameChecker = (control:FormControl) => {
    let rg1 = new RegExp(/^[^\\/:\*\?"<>\|]+$/); // forbidden characters \ / : * ? " < > |
    let rg2 = new RegExp(/^\./); // cannot start with dot (.)
    let rg3 = new RegExp(/^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i); // forbidden file names
    if(!rg1.test(control.value)){
      return {hasInvalidCharacters:false}
    }
    if(rg2.test(control.value)){
        return {startsPeriod:false}
    }
    if(rg3.test(control.value)){
      return {bannedNames:false}
    }
    return null
  }

  findingsAddNotefileNameChecker = (control: FormControl) => {
    let rg1 = new RegExp(/^[^\\/:\*\?"<>\|]+$/); // forbidden characters \ / : * ? " < > |
    let rg2 = new RegExp(/^\./); // cannot start with dot (.)
    let rg3 = new RegExp(/^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i); // forbidden file names
    // const hasExtension: string | boolean = control.value ? control.value.match(/\.[0-9a-z]+$/i) : false;
    // let validExtension: boolean;
    // if (hasExtension && hasExtension[0].length > 3) { // Extension must be > 3 chars since period is included.
    //   switch (hasExtension[0]) {
    //     case '.xls':
    //     case '.xlsx':
    //     case '.doc':
    //     case '.docx':
    //     case '.pdf':
    //     case '.csv':
    //     case '.ppt':
    //     case '.pptx':
    //       validExtension = true;
    //       break;
    //     default:
    //       validExtension = false;
    //       break;
    //   }
    // } else {
    //   validExtension = false;
    // }
    if (!control.value) {
      return {required: false}
    }
    if (!rg1.test(control.value)) {
      return {hasInvalidCharacters: false}
    }
    if (rg2.test(control.value)) {
      return {startsPeriod: false}
    }
    if (rg3.test(control.value)) {
      return {bannedNames: false}
    }
    // if (!hasExtension || !validExtension) {
    //   return {invalidExtension: false}
    // }
    return null
  }

  autoCompleteInvalid = (control: FormControl) => {
    if(control.value){
      if(typeof control.value !== 'object'){
        return {notValidSelection:false}
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  spaceChecker = (control:FormControl) => {
    if(control.value){
      if(control.value.charAt( 0 ) === " "){
        return {startsWhitepace:false}
      }
      if(control.value.slice(-1) === " "){
        return {endsWhitepace:false}
      }
      return null;
    }
    else {
      return null;
    }
  }

  emailValidator = (control: FormControl) => {
    var emailPattern = new RegExp(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)
    return emailPattern.test(control.value) ? null : {
       invalidEmailAddress: false
    };
  }

  linkValidator = (control: FormControl) => {
    var linkPattern = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/);
    return linkPattern.test(control.value) ? null : {
       invalidLink: false
    };
  }

 matchingPasswords = (passwordKey: string, passwordConfirmationKey: string) => {
   return (group: FormGroup) => {
     let passwordInput = group.controls[passwordKey];
     let passwordConfirmationInput = group.controls[passwordConfirmationKey];
     if (passwordInput.value && passwordConfirmationInput.value  && passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true});
     }
   }
 }


 existAlready = (list:[any], key:string) => {
    return (control: FormControl) => {
      if(!list || !list.length){return null}
      let cleaned = control.value.replace(' ','');
      let existcheck = list.filter((item)=>{
        if(key){
          if(item[key] == cleaned){ return item; }
        }
        else {
          if(cleaned == item){ return item; }
        }
      });
      if(existcheck.length){
        return {notUniqueMember: true}
      }
      else {
         return null;
      }
    }
 }


 beforeTodayValidator = (control:FormControl) => {
   let test = moment(control.value, "MM/DD/YYYY");
   let today = moment();
   if(test.isBefore(today) && !test.isSame(today, 'day')){
      return {invalidDate: true}
   }
   else {
     return null
   }
 }

 dateValidator = (control:FormControl) => {
   if(control.value){
     let test = moment(control.value, "MM/DD/YYYY");
     if(test.toDate() instanceof Date){
       return null
     }
     else {
       return {invalidDate: true}
     }
   }
   else {
     return null;
   }
 }


 datesInOrder = (startKey:string, endKey:string, confirmKey:string) => {
   return (group: FormGroup) => {
     let today = moment();
     let startInput = group.controls[startKey];
     let endInput = group.controls[endKey];
     let confrimInput = group.controls[confirmKey];

     if(startInput.value && endInput.value){
       let start = moment(startInput.value);
       let end =  moment(endInput.value);
       let today = moment();
       startInput.markAsUntouched({onlySelf: true})
       if(start.isAfter(end, 'day')){
         startInput.markAsTouched({onlySelf: true})
         return startInput.setErrors({invalidDate: true})
       }

       if(end.isBefore(start, 'day')){
         endInput.markAsTouched({onlySelf: true})
         return endInput.setErrors({invalidDate: true})
       }

       if(start.isBefore(today) && !start.isSame(today, 'day')){
          return {invalidDate: true}
       }

       return startInput.setErrors(null)

     }

     if(startInput.value && confrimInput.value){
        let start =  moment(startInput.value);
        let confrim = moment(confrimInput.value);
        let today = moment();

        if(confrim.isAfter(start, 'day')){
          return confrimInput.setErrors({invalidDate: true});
        }
     }//end confrim
   }
 }

 editDatesInOrder = (startKey:string, endKey:string, confirmKey:string) => {
   return (group: FormGroup) => {
     let today = moment();
     let startInput = group.controls[startKey];
     let endInput = group.controls[endKey];
     let confrimInput = group.controls[confirmKey];

     if(startInput.value && endInput.value){
       let start = moment(startInput.value);
       let end =  moment(endInput.value);
       let today = moment();
       startInput.markAsUntouched({onlySelf: true})
       if(start.isAfter(end, 'day')){
         startInput.markAsTouched({onlySelf: true})
         return startInput.setErrors({invalidDate: true})
       }

       if(end.isBefore(start, 'day')){
         return endInput.setErrors({invalidDate: true})
       }

       return startInput.setErrors(null);
     }

     if(startInput.value && confrimInput.value){
       let confrim = moment(confrimInput.value);
       let start = moment(startInput.value);
       if(confrim.isAfter(start, 'day')){
        console.error('confrim after start')
        return confrimInput.setErrors({invalidDate: true})
       }

       else {
         return confrimInput.setErrors(null);
       }
     }
   }
 }
}
