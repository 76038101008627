import { OnDestroy } from '@angular/core';
import { from } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
// @Injectable() makes this a provider and must be included, it should be noted that providers are instated once and are shared across the application
var EventsService = /** @class */ (function () {
    function EventsService() {
        var _this = this;
        this.notification = [];
        this.on = function (name, listener) {
            if (!_this.listeners[name]) {
                _this.listeners[name] = [];
            }
            _this.listeners[name].push(listener);
        };
        this.broadcast = function (name, args) {
            _this.eventsSubject.next({ name: name, args: args });
        };
        this.setNotification = function (notification) {
            _this.notification.push(notification);
        };
        this.clearNotification = function () {
            _this.notification = [];
        };
        this.getNotification = function () {
            return _this.notification;
        };
        this.listeners = {};
        this.eventsSubject = new Subject();
        this.events = from(this.eventsSubject);
        this.memorycheck = this.events.subscribe(function (_a) {
            var name = _a.name, args = _a.args;
            if (_this.listeners[name]) {
                for (var _i = 0, _b = _this.listeners[name]; _i < _b.length; _i++) {
                    var listener = _b[_i];
                    listener(args);
                }
            }
        });
    }
    EventsService.prototype.ngOnDestroy = function () {
        // prevent memory leak when component destroyed
        this.memorycheck.unsubscribe();
    };
    return EventsService;
}());
export { EventsService };
