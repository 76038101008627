import {Injectable} from '@angular/core';
import {IUser} from '../interfaces-classes/IUser';

import {ITeamMember, IDownloadAllInfo} from '../interfaces-classes';
import {IUserPermissions} from '../interfaces-classes/IUserPermissions.interface';
import {environment} from '../../environments/environment';
import {BaseService} from './base.service';
import {EventsService} from './events.service';

@Injectable()
export class UserService extends BaseService {
  private user: IUser;
  private downloadAllInfo: IDownloadAllInfo;

  constructor(
    private _event: EventsService
  ) {
    super(
      _event
    );
  }

  getUser = (): IUser => {
    return <IUser>JSON.parse(sessionStorage.getItem('user'));
  }

  getToken = (): string => {
    return <string>JSON.parse(sessionStorage.getItem('token')) || null;
  }

  clearUser = () => {
    window.sessionStorage.removeItem('user');
    this.user = null;
  }

  setUser = (user: IUser) => {
    this.user = user;
    sessionStorage.setItem('user', JSON.stringify(user));
    return this.getUser();
  }

  setDownloadAllInfo = (info: IDownloadAllInfo) => {
    this.downloadAllInfo = info;
    sessionStorage.setItem('dlInfo', JSON.stringify(info));
  }

  getDownloadAllInfo = () => {
      let dlInfo =  <IDownloadAllInfo>JSON.parse(sessionStorage.getItem('dlInfo') || null);
      // downloadAllInfo should only be read once, so delete after retrieving
      sessionStorage.removeItem('dlInfo');
      this.downloadAllInfo = null;
      return dlInfo;
  }

  updateInfoIfCurrentUser = (updatedUser: IUser | ITeamMember) => {
    const sessionUser = this.getUser();
    const isCurrentUser = sessionUser.id === updatedUser.id;
    if (isCurrentUser) {
      sessionUser.firstName = updatedUser.firstName;
      sessionUser.lastName = updatedUser.lastName;
      this.setUser(sessionUser);
    }

    return isCurrentUser;
  }

  adminPerms = (key: string): boolean => {
    // pass invalid workspaceId, so it only checks in globalPermissions / archivedPermissions
    return this.workspacePerms('-1')[key];
  }

  private getPermissions = (permissions: string[]): IUserPermissions => {
    const perms: IUserPermissions = {
      canView: false,
      canEdit: false,
      canDelete: false,
      canDownload: false,
      canAdd: false,
      canOpenActivities: false,
      canEditActivitieStatus: false,
      canViewUsers: false,
      canEditUsers: false,
      canManageNotifications: false,
      canEditResources: false,
      canAccessTeamFolder: false,
      canAddToTeam: false,
      canAddToShared: false,
      canViewProfiles: false,
      canDownloadFinding: false,
      canChangeFindingStatus: false,
      canSendFindingWorkspace: false,
      canViewIEQCalcConfig: false,
      canEditIEQCalcConfig: false,
      canUpdateFinding: false,
      canUpdateOwnResources: false,
      cmFindingsAdmin: false,
      cmFindingsTeamMember: false,
      cmFindingsTeamLead: false,
      cmFindingsContributor: false,
      canShareAerReport: false
    }

    permissions.forEach((item) => {
      switch (item) {
        case 'can_view_all_workspaces':
          perms.canView = true;
          break;
        case 'can_edit_workspace':
        case 'can_edit_all_workspaces':
          perms.canEdit = true;
          break;
        case 'can_delete_workspace':
          perms.canDelete = true;
          break;
        case 'can_download_workspace_list':
          perms.canDownload = true;
          break;
        case 'can_add_workspace':
          perms.canAdd = true;
          break;
        case 'can_manage_activities':
          perms.canOpenActivities = true;
          break;
        case 'can_change_activity_status':
          perms.canEditActivitieStatus = true;
          break;
        case 'can_view_users':
          perms.canViewUsers = true;
          break;
        case 'can_manage_users':
          perms.canEditUsers = true;
          break;
        case 'can_manage_notifications':
          perms.canManageNotifications = true;
          break;
        case 'can_edit_delete_all_resources':
          perms.canEditResources = true;
          break;
        case 'can_access_team_only_folder':
          perms.canAccessTeamFolder = true;
          break;
        case 'can_add_to_team':
          perms.canAddToTeam = true;
          break;
        case 'can_add_to_shared':
          perms.canAddToShared = true;
          break;
        case 'can_view_teammembers_profile':
          perms.canViewProfiles = true;
          break;
        case 'can_download_finding_report':
          perms.canDownloadFinding = true;
          break;
        case 'can_complete_reopen_finding':
          perms.canChangeFindingStatus = true;
          break;
        case 'can_send_finding_to_resources':
          perms.canSendFindingWorkspace = true;
          break;
        case 'can_view_ieq_calc_config':
          perms.canViewIEQCalcConfig = true;
          break;
        case 'can_edit_ieq_calc_config':
          perms.canEditIEQCalcConfig = true;
          break;
        case 'can_update_finding':
          perms.canUpdateFinding = true;
          break;
        case 'can_update_own_resources':
          perms.canUpdateOwnResources = true;
          break;
        case 'cm_findings_admin':
          perms.cmFindingsAdmin = true;
          break;
        case 'cm_findings_team_member':
          perms.cmFindingsTeamMember = true;
          break;
        case 'cm_findings_contributor':
          perms.cmFindingsContributor = true;
          break;
        case 'cm_findings_team_lead':
          perms.cmFindingsTeamLead = true;
          break;
        case 'can_share_aer_report':
          perms.canShareAerReport = true;
          break;
        default:
          break;
      }
    });

    // This should never be used in production, only for dev/testing.
    if (!environment.production && this._mocksOn) {
      perms.cmFindingsContributor = true;
      perms.cmFindingsAdmin = false;
      perms.cmFindingsTeamMember = false;
      perms.cmFindingsTeamLead = false;
    }

    return perms;
  }

  workspacePerms = (key: string): IUserPermissions => {
    // archived permissions will over rule even admin permissions
    if (this.getUser() && this.getUser().archivedPermissions != null) {
      return this.getPermissions(this.getUser().archivedPermissions);
    } else if (this.getUser() && this.getUser().globalPermissions) {
      return this.getPermissions(this.getUser().globalPermissions);
    } else if (this.getUser() && this.getUser().workspaces && this.getUser().workspaces[key]) {
      const workspacePerms = this.getPermissions(this.getUser().workspaces[key].permissionList);
      // always add canView permission if user has access to a specific ws
      workspacePerms.canView = true;
      return workspacePerms;
    } else {
      return this.getPermissions([]);
    }
  }
}
