import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpHeaders, HttpParameterCodec, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { EventsService  } from './events.service';
import {Observable} from 'rxjs/internal/Observable';
import {throwError} from 'rxjs/internal/observable/throwError';


class BaseEncoder implements HttpParameterCodec {
  encodeKey(k: string): string {
    return encodeURIComponent(k);
  }
  encodeValue(v: string): string {
    return encodeURIComponent(v);
  }
  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }
  decodeValue(v: string): string {
    return decodeURIComponent(v)
  }
}


@Injectable()
export class BaseService {
  // protected headers: Headers;
  protected token: string;
  public baseUrl: string;
  public resourceUrl: string;
  public reportUrl: string;
  protected _mocksOn: boolean;
  constructor(
    public event: EventsService
  ) {
    this.baseUrl = environment.baseUrl;
    this.resourceUrl  = environment.resourceBase;
    this.reportUrl = environment.reportUrl;
    this._mocksOn = false;
  }

  get headers(): HttpHeaders {
    this.token = <string>JSON.parse(sessionStorage.getItem('token'));
    const temp: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token ? this.token : ''
    });

    return temp;
  }

  get options(): { headers?: HttpHeaders; reportProgress?: boolean; params?: HttpParams; responseType?: 'arraybuffer' | 'blob' | 'text' | 'json' | any; withCredentials?: boolean; } {
    return { headers: this.headers , withCredentials: true };
  }

  getQueryOptions = (querys: Object): {
    headers?: HttpHeaders;
    reportProgress?: boolean;
    params?: HttpParams;
    responseType?: 'arraybuffer' | 'blob' | 'text' | 'json' | any;
    withCredentials?: boolean;
  } => {
    let params: HttpParams = new HttpParams({encoder: new BaseEncoder()});
    for (const property in querys) {
      if (querys.hasOwnProperty(property)) {
        params = params.append(property, querys[property]);
      }
    }
    return {headers: this.headers, params: params, withCredentials: true};
  }

  setToken = (token: string) => {
    sessionStorage.setItem('token', JSON.stringify(token));
  }

  clearToken = () => {
    this.token = null;
    window.sessionStorage.removeItem('token');
  }

  handleError = (error: HttpErrorResponse): Observable<HttpErrorResponse> => {
    if(error.status === 0 || error.status === 401){
      this.event.broadcast('logOut', true);
    }
    return throwError(error);
  }

}
