var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { EventsService } from './events.service';
var UserService = /** @class */ (function (_super) {
    __extends(UserService, _super);
    function UserService(_event) {
        var _this = _super.call(this, _event) || this;
        _this._event = _event;
        _this.getUser = function () {
            return JSON.parse(sessionStorage.getItem('user'));
        };
        _this.getToken = function () {
            return JSON.parse(sessionStorage.getItem('token')) || null;
        };
        _this.clearUser = function () {
            window.sessionStorage.removeItem('user');
            _this.user = null;
        };
        _this.setUser = function (user) {
            _this.user = user;
            sessionStorage.setItem('user', JSON.stringify(user));
            return _this.getUser();
        };
        _this.setDownloadAllInfo = function (info) {
            _this.downloadAllInfo = info;
            sessionStorage.setItem('dlInfo', JSON.stringify(info));
        };
        _this.getDownloadAllInfo = function () {
            var dlInfo = JSON.parse(sessionStorage.getItem('dlInfo') || null);
            // downloadAllInfo should only be read once, so delete after retrieving
            sessionStorage.removeItem('dlInfo');
            _this.downloadAllInfo = null;
            return dlInfo;
        };
        _this.updateInfoIfCurrentUser = function (updatedUser) {
            var sessionUser = _this.getUser();
            var isCurrentUser = sessionUser.id === updatedUser.id;
            if (isCurrentUser) {
                sessionUser.firstName = updatedUser.firstName;
                sessionUser.lastName = updatedUser.lastName;
                _this.setUser(sessionUser);
            }
            return isCurrentUser;
        };
        _this.adminPerms = function (key) {
            // pass invalid workspaceId, so it only checks in globalPermissions / archivedPermissions
            return _this.workspacePerms('-1')[key];
        };
        _this.getPermissions = function (permissions) {
            var perms = {
                canView: false,
                canEdit: false,
                canDelete: false,
                canDownload: false,
                canAdd: false,
                canOpenActivities: false,
                canEditActivitieStatus: false,
                canViewUsers: false,
                canEditUsers: false,
                canManageNotifications: false,
                canEditResources: false,
                canAccessTeamFolder: false,
                canAddToTeam: false,
                canAddToShared: false,
                canViewProfiles: false,
                canDownloadFinding: false,
                canChangeFindingStatus: false,
                canSendFindingWorkspace: false,
                canViewIEQCalcConfig: false,
                canEditIEQCalcConfig: false,
                canUpdateFinding: false,
                canUpdateOwnResources: false,
                cmFindingsAdmin: false,
                cmFindingsTeamMember: false,
                cmFindingsTeamLead: false,
                cmFindingsContributor: false,
                canShareAerReport: false
            };
            permissions.forEach(function (item) {
                switch (item) {
                    case 'can_view_all_workspaces':
                        perms.canView = true;
                        break;
                    case 'can_edit_workspace':
                    case 'can_edit_all_workspaces':
                        perms.canEdit = true;
                        break;
                    case 'can_delete_workspace':
                        perms.canDelete = true;
                        break;
                    case 'can_download_workspace_list':
                        perms.canDownload = true;
                        break;
                    case 'can_add_workspace':
                        perms.canAdd = true;
                        break;
                    case 'can_manage_activities':
                        perms.canOpenActivities = true;
                        break;
                    case 'can_change_activity_status':
                        perms.canEditActivitieStatus = true;
                        break;
                    case 'can_view_users':
                        perms.canViewUsers = true;
                        break;
                    case 'can_manage_users':
                        perms.canEditUsers = true;
                        break;
                    case 'can_manage_notifications':
                        perms.canManageNotifications = true;
                        break;
                    case 'can_edit_delete_all_resources':
                        perms.canEditResources = true;
                        break;
                    case 'can_access_team_only_folder':
                        perms.canAccessTeamFolder = true;
                        break;
                    case 'can_add_to_team':
                        perms.canAddToTeam = true;
                        break;
                    case 'can_add_to_shared':
                        perms.canAddToShared = true;
                        break;
                    case 'can_view_teammembers_profile':
                        perms.canViewProfiles = true;
                        break;
                    case 'can_download_finding_report':
                        perms.canDownloadFinding = true;
                        break;
                    case 'can_complete_reopen_finding':
                        perms.canChangeFindingStatus = true;
                        break;
                    case 'can_send_finding_to_resources':
                        perms.canSendFindingWorkspace = true;
                        break;
                    case 'can_view_ieq_calc_config':
                        perms.canViewIEQCalcConfig = true;
                        break;
                    case 'can_edit_ieq_calc_config':
                        perms.canEditIEQCalcConfig = true;
                        break;
                    case 'can_update_finding':
                        perms.canUpdateFinding = true;
                        break;
                    case 'can_update_own_resources':
                        perms.canUpdateOwnResources = true;
                        break;
                    case 'cm_findings_admin':
                        perms.cmFindingsAdmin = true;
                        break;
                    case 'cm_findings_team_member':
                        perms.cmFindingsTeamMember = true;
                        break;
                    case 'cm_findings_contributor':
                        perms.cmFindingsContributor = true;
                        break;
                    case 'cm_findings_team_lead':
                        perms.cmFindingsTeamLead = true;
                        break;
                    case 'can_share_aer_report':
                        perms.canShareAerReport = true;
                        break;
                    default:
                        break;
                }
            });
            // This should never be used in production, only for dev/testing.
            if (!environment.production && _this._mocksOn) {
                perms.cmFindingsContributor = true;
                perms.cmFindingsAdmin = false;
                perms.cmFindingsTeamMember = false;
                perms.cmFindingsTeamLead = false;
            }
            return perms;
        };
        _this.workspacePerms = function (key) {
            // archived permissions will over rule even admin permissions
            if (_this.getUser() && _this.getUser().archivedPermissions != null) {
                return _this.getPermissions(_this.getUser().archivedPermissions);
            }
            else if (_this.getUser() && _this.getUser().globalPermissions) {
                return _this.getPermissions(_this.getUser().globalPermissions);
            }
            else if (_this.getUser() && _this.getUser().workspaces && _this.getUser().workspaces[key]) {
                var workspacePerms = _this.getPermissions(_this.getUser().workspaces[key].permissionList);
                // always add canView permission if user has access to a specific ws
                workspacePerms.canView = true;
                return workspacePerms;
            }
            else {
                return _this.getPermissions([]);
            }
        };
        return _this;
    }
    return UserService;
}(BaseService));
export { UserService };
