import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import {Observable, of, of as observableOf} from "rxjs";
import {IDownloadAllInfo, IUser} from "../../interfaces-classes";
import {AuthService} from '../../global-services/auth.service';
import {UserService} from '../../global-services/user.service';
import {HttpErrorResponse} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {EventsService} from '../../global-services/events.service';

@Injectable()
export class DownloadsResolveService implements Resolve<any>{
    public displayModal: boolean = false;
    private downloadAllToken: string = null;
    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private userService: UserService,
        private event: EventsService
    ){
    }
    resolve(route: ActivatedRouteSnapshot): Observable<any>{
        let token = route.queryParams['authToken'];
        let downloadAllToken = route.queryParams['downloadAllToken'];
        if(!token){
            this.event.broadcast('logOut', true);
            throw new Error("error")
        }
        const authData = {authToken: token, downloadAllToken:downloadAllToken};
        return this.authService.login(authData).pipe(map((response:{ user: IUser, token: string, downloadAllInfo: IDownloadAllInfo }) => {
                const user = response.user;
                this.authService.setToken(response.token);
                this.userService.setUser(user);
                if (!user.acceptTandC) {
                    this.event.broadcast('isNavigating', false);
                    this.displayModal = true;
                }else{
                    return response;
                }
            })
            ,catchError((error: HttpErrorResponse) => {
                console.error('Error authorizing download request:', error)
                if(error.status === 0 || error.status === 401){
                    this.event.broadcast('logOut', true);
                }
                throw new Error("error")
            }),);
    }
}