import {Component, OnInit} from '@angular/core';
import {RouterLoadingService} from './global-services/router-loading.service';
import {MessageService} from 'primeng/api';
import {SharedMyMessageService} from './global-services/shared-my-message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private _routerLoadingService: RouterLoadingService,
    private _messageService: MessageService,
    private _sharedMyMessageService: SharedMyMessageService
  ) {
  }

  ngOnInit() {
    this._sharedMyMessageService.showSuccess$.subscribe(
      (event: { severity: string, detail: string, summary?: string, key?: string }) => {
        this._showSuccess(event);
      }
    );
    this._sharedMyMessageService.showInfo$.subscribe(
      (event: { severity: string, detail: string, summary?: string, key?: string }) => {
        this._showInfo(event);
      }
    );
    this._sharedMyMessageService.showWarn$.subscribe(
      (event: { severity: string, detail: string, summary?: string, key?: string }) => {
        this._showWarn(event);
      }
    );
    this._sharedMyMessageService.showError$.subscribe(
      (event: { severity: string, detail: string, summary?: string, key?: string }) => {
        this._showError(event);
      }
    );
    this._sharedMyMessageService.showCustom$.subscribe(
      (event: { severity: string, detail: string, summary?: string, key?: string }) => {
        this._showCustom(event);
      }
    );
  }

  // ==========PrimeNG Toast logic START==================================================================================================
  // Toast logic starts here - the Toast component is designed to be used with a component template. We place it here in                //
  // the AppComponent to enable app-wide notifications via toasts and the MessageService.                                               //
  // =====================================================================================================================================
  private _showSuccess(content: { severity: string, detail: string, summary?: string, key?: string }) {
    // this._messageService.add(content);
  }

  private _showInfo(content: { severity: string, detail: string, summary?: string, key?: string }) {
    // this._messageService.add(content);
  }

  private _showWarn(content: { severity: string, detail: string, summary?: string, key?: string }) {
    // this._messageService.add(content);
  }

  private _showError(content: { severity: string, detail: string, summary?: string, key?: string }) {
    // this._messageService.add(content);
  }

  private _showCustom(content: { severity: string, detail: string, summary?: string, key?: string }) {
    // this._messageService.add(content);
  }
}
