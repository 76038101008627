import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {UserService} from '../../global-services/user.service';
import {EventsService} from '../../global-services/events.service';
import {AuthService} from '../../global-services/auth.service';
import {environment} from '../../../environments/environment';
import {DownloadReportTypeEnum} from "../../interfaces-classes/DownloadReportType.enum";
import {IDownloadAllInfo} from "../../interfaces-classes";

@Component({
  selector: 'downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {

  constructor(
      public route: ActivatedRoute,
      private router: Router,
      private userService: UserService,
      private authService: AuthService,
      private event: EventsService
  ) {

    this.route.data.subscribe((data: any) => {
      //code for download all
       console.log('downloadAllInfo', data.response.downloadAllInfo);
       if (data.response.downloadAllInfo && data.response.downloadAllInfo.workspace){
        this.userService.setDownloadAllInfo(data.response.downloadAllInfo);
        this.finishLoginDownloadAll(data.response.downloadAllInfo);
      } else {
        //if no download info found, this usually means the data is corrupted on server side, just login
        this.finishLogin();
      }
    })

    this.route.queryParams.subscribe((params) => {
       let reportName = params['reportName'];
       if (reportName == environment.SEND_TO_WORKSPACE_REPORT) {
          this.router.navigate(['/workspaces']);
       }
    });
  }

  finishLoginDownloadAll = (downloadAllInfo: IDownloadAllInfo) => {
    this.event.broadcast('isLoading', false);
    this.authService.loggedIn = true;
      if (downloadAllInfo.reportDownloadType &&
          (downloadAllInfo.reportDownloadType == DownloadReportTypeEnum.AER_REPORT
              || downloadAllInfo.reportDownloadType == DownloadReportTypeEnum.CER_REPORT)) {
          this.router.navigate(['workspaces'])
      } else {
          console.log("workspaceId:" + downloadAllInfo.workspace.id);
          this.router.navigate(['/workspace-view', downloadAllInfo.workspace.id, 'resources', 'download-all']);
      }

  }

  finishLogin = () => {
      this.event.broadcast('isLoading', false);
      this.authService.loggedIn = true;
      this.router.navigate(['workspaces'])
  }

  ngOnInit() {
  }

}
