import { Component} from '@angular/core';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-version',
  styles: ['p { font-weight: bold; margin:10px; }'],
  template: `
    <p>Build Date: {{ versionInfo.date | date:'medium'}}</p>
    <p>Version: {{ versionInfo.version}}</p>
    <p>Revision: {{ versionInfo.revision}}</p>
    <p>Branch: {{ versionInfo.branch}}</p>
    <p>Build Env: {{buildEnv}}</p>
  `
})
export class AppVersion {
  versionInfo:any = null;
  buildEnv:string = '';
  constructor() {
    this.buildEnv = environment.envname;
    if(environment.version && environment.version.date){
      this.versionInfo = environment.version;
    }
    else{
      this.versionInfo = "No Version Availble Run Npm Build Or Version Script";
    }
  }
}
