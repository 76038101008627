import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from './global-services/auth-guard.service';
import { AppVersion  } from './global-components/version.component';
import { DownloadsComponent } from './downloads-module/downloads/downloads.component';
import {DownloadsResolveService} from "./downloads-module/services/downloads-resolve.service";

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: './login-module/login.module#LoginModule'
  },
  {
    path: 'login/:downloadAllToken',
    loadChildren: './login-module/login.module#LoginModule'
  },
  {
    path: 'workspace-view',
    canActivateChild: [AuthGuardService],
    loadChildren: './workspace-view-module/workspace-view.module#WorkspaceViewModule'
  },
  {
    path: 'workspaces',
    canActivateChild: [AuthGuardService],
    loadChildren: './workspaces-module/workspaces.module#WorkspacesModule'
  },
  {
    path: 'diagnostic',
    canActivateChild: [AuthGuardService],
    loadChildren: './diagnostic-module/diagnostic.module#DiagnosticModule'
  },
  {
    path: 'download-report',
    component: DownloadsComponent,
    resolve: {
      response: DownloadsResolveService
    }
  },
  { path: 'version',   component: AppVersion},
  { path: '**', redirectTo: 'login'}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [],
  exports: [RouterModule]
})


export class AppRoutingModule { }
