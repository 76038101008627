
  <div class="overlay-lock"
       [ngClass]="{
        'detail-active': overlayActive,
        'menu-active': menuActive,
        'loading-active': loading,
        'navigating-active': navigating
       }"
  >
    <ng-content></ng-content>
    <div class="panel-overlay">
      <div class="overlay-loader"><i class="fa fa-spinner fa-spin"></i></div>
      <div class="loader-text">Loading...</div>
    </div>