import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationService } from '../global-services/validation.service';
import {ErrorMsgComponent} from '../global-components/error-msg.component';
import {FormErrorMsgComponent} from '../global-components/form-error-msg.component';
import {DialogModule, MenuModule} from 'primeng/primeng';
import { HeaderComponent } from './header/header.component';
import {OverlayLockComponent} from '../global-components/overlay-lock.component';
import { RolePipe } from './role.pipe';
import { EmptyPipe } from './empty.pipe';
import { ScrollLoaderComponent } from './scroll-loader/scroll-loader.component';
import { HighlightDirective } from './directives/highlight.directive';
import { GridClickCellComponent } from './grid-click-cell/grid-click-cell.component';
import {MessagesModule} from 'primeng/primeng';
import { SearchInputComponent } from './search-input/search-input.component';
import { FormsModule } from '@angular/forms';
import { BannerComponent } from './banner/banner.component';
import { ToastMessageComponent } from './toast-message/toast-message.component';
import { MyPopoverComponent } from './my-popover/my-popover.component';
import { MyErrorComponent } from './my-error/my-error.component';
import { DownloadReportModalComponent } from './download-report-modal/download-report-modal.component';
import {CalendarSyncViewModelDirective} from '../directives/calendar-sync-view-model.directive';
import {RichContentComponent} from "./rich-content/rich-content.component";
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { SafeHtmlPipe } from './safe-html.pipe';
import { DownloadConfirmationModalComponent } from './download-confirmation-modal/download-confirmation-modal.component';
import { ConfirmCancelModalComponent } from './confirm-cancel-modal/confirm-cancel-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MenuModule,
    MessagesModule,
    FormsModule,
    DialogModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  declarations: [
    GridClickCellComponent,
    ErrorMsgComponent,
    FormErrorMsgComponent,
    HeaderComponent,
    OverlayLockComponent,
    RolePipe,
    EmptyPipe,
    ScrollLoaderComponent,
    HighlightDirective,
    SearchInputComponent,
    BannerComponent,
    ToastMessageComponent,
    MyPopoverComponent,
    MyErrorComponent,
    DownloadReportModalComponent,
    CalendarSyncViewModelDirective,
    RichContentComponent,
    SafeHtmlPipe,
    DownloadConfirmationModalComponent,
    ConfirmCancelModalComponent
  ],
  providers: [ValidationService],
    exports: [
        ErrorMsgComponent,
        FormErrorMsgComponent,
        HeaderComponent,
        OverlayLockComponent,
        RolePipe,
        EmptyPipe,
        ScrollLoaderComponent,
        HighlightDirective,
        GridClickCellComponent,
        SearchInputComponent,
        BannerComponent,
        ToastMessageComponent,
        MyPopoverComponent,
        MyErrorComponent,
        DownloadReportModalComponent,
        CalendarSyncViewModelDirective,
        RichContentComponent,
        SafeHtmlPipe,
        DownloadConfirmationModalComponent,
        ConfirmCancelModalComponent
    ]
})
export class AppSharedModule { }
