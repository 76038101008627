import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './routes';
import { AppComponent } from './app.component';
import {AppSharedModule} from './shared-module/shared.module';
import { EventsService  } from './global-services/events.service';
import {AuthService} from './global-services/auth.service';
import {UserService} from './global-services/user.service';
import {BaseService} from './global-services/base.service';
import {RouterLoadingService} from './global-services/router-loading.service';

import { AppVersion  } from './global-components/version.component';
import {AuthGuardService} from './global-services/auth-guard.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {httpInterceptorProviders} from './http-interceptors';
import { DownloadsComponent } from './downloads-module/downloads/downloads.component';
import {DownloadsResolveService} from "./downloads-module/services/downloads-resolve.service";
import {AppConfigModule} from "./app-config.module";
// Import all Froala Editor plugins.
import 'froala-editor/js/plugins.pkgd.min.js';



@NgModule({
  declarations: [
    AppComponent,
    AppVersion,
    DownloadsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    AppSharedModule,
    ToastModule,
    AppConfigModule
  ],
  providers: [
    AuthService,
    UserService,
    BaseService,
    EventsService,
    AuthGuardService,
    RouterLoadingService,
    MessageService,
    DownloadsResolveService,
    httpInterceptorProviders // The interceptors must be provided here, because interceptors imported after the HttpClientModule are ignored.
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
