import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    Router,
    Route,
    CanLoad,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    RouterState
} from '@angular/router';
import {AuthService} from './auth.service';
import {UserService} from './user.service';
import {BaseService} from './base.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild{
    state: RouterState
    snapshot: RouterStateSnapshot

    constructor(
      public authService: AuthService,
      private baseService: BaseService,
      private userService: UserService,
      public router: Router
    ) {
    }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let url = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let url = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): Observable<boolean> {
    if (!this.authService.loggedIn) {
      const loginCheck = new Observable<boolean>((observer) => {
        this.authService.testLogin().subscribe((response) => {
            this.authService.loggedIn = true;
            this.authService.setToken(response.token)
            // make sure we preserve the archived permissions when auth gard is triggered, because archived permissions
            // are not contained in response of /me endpoint
            response.user.archivedPermissions = this.userService.getUser().archivedPermissions;
            this.userService.setUser(response.user);
            observer.next(true);
          },
          (error: HttpErrorResponse) => {
            this.authService.loggedIn = false;
            this.authService.redirectUrl = url;
            this.userService.clearUser();
            this.authService.clearToken();
            this.router.navigate(['/login']);
            observer.next(false);
          });
      });

      return loginCheck
    } else {
      return observableOf(true);
    }
  }
}
