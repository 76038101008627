/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./global-components/overlay-lock.component.ngfactory";
import * as i2 from "./global-components/overlay-lock.component";
import * as i3 from "./global-services/events.service";
import * as i4 from "@angular/router";
import * as i5 from "../../node_modules/primeng/components/toast/toast.ngfactory";
import * as i6 from "primeng/components/toast/toast";
import * as i7 from "primeng/components/common/messageservice";
import * as i8 from "./app.component";
import * as i9 from "./global-services/router-loading.service";
import * as i10 from "./global-services/shared-my-message.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["id", "page-box"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 5, "overlay-lock", [], null, null, null, i1.View_OverlayLockComponent_0, i1.RenderType_OverlayLockComponent)), i0.ɵdid(3, 49152, null, 0, i2.OverlayLockComponent, [i3.EventsService], null, null), (_l()(), i0.ɵted(-1, 0, ["\n    "])), (_l()(), i0.ɵeld(5, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(6, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵted(-1, 0, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "p-toast", [["id", "my-toast"], ["key", "toast"], ["position", "bottom-right"]], null, null, null, i5.View_Toast_0, i5.RenderType_Toast)), i0.ɵdid(10, 1294336, null, 1, i6.Toast, [i7.MessageService], { key: [0, "key"], position: [1, "position"] }, null), i0.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 6, 0); var currVal_0 = "toast"; var currVal_1 = "bottom-right"; _ck(_v, 10, 0, currVal_0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i8.AppComponent, [i9.RouterLoadingService, i7.MessageService, i10.SharedMyMessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
