import { Injectable, OnDestroy} from '@angular/core';
import {from, Observable, Subscription} from 'rxjs';
import {Subject} from 'rxjs/internal/Subject';
// @Injectable() makes this a provider and must be included, it should be noted that providers are instated once and are shared across the application
@Injectable()
export class EventsService implements OnDestroy {
  public eventsSubject: Subject<any>;
  public listeners:any;
  public events: Observable<any>;
  private memorycheck: Subscription;
  public notification: any[] = [];

  constructor() {
    this.listeners = {};
    this.eventsSubject = new Subject();

    this.events = from(this.eventsSubject);

    this.memorycheck = this.events.subscribe(({name, args}) => {
      if (this.listeners[name]) {
        for (let listener of this.listeners[name]) {
          listener(args);
        }
      }
    });
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.memorycheck.unsubscribe();
  }

  on = (name, listener) => {
    if (!this.listeners[name]) {
        this.listeners[name] = [];
    }

    this.listeners[name].push(listener);
  }

  broadcast = (name, args) => {
    this.eventsSubject.next({name, args});
  }

  setNotification = (notification:any) => {
    this.notification.push(notification)
  }

  clearNotification = () => {
    this.notification = [];
  }

  getNotification = () => {
    return this.notification
  }


}
