/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./version.component";
var styles_AppVersion = ["p[_ngcontent-%COMP%] { font-weight: bold; margin:10px; }"];
var RenderType_AppVersion = i0.ɵcrt({ encapsulation: 0, styles: styles_AppVersion, data: {} });
export { RenderType_AppVersion as RenderType_AppVersion };
export function View_AppVersion_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["Build Date: ", ""])), i0.ɵppd(4, 2), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["Version: ", ""])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["Revision: ", ""])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["Branch: ", ""])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(15, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(16, null, ["Build Env: ", ""])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v, 0), _co.versionInfo.date, "medium")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.versionInfo.version; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.versionInfo.revision; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.versionInfo.branch; _ck(_v, 13, 0, currVal_3); var currVal_4 = _co.buildEnv; _ck(_v, 16, 0, currVal_4); }); }
export function View_AppVersion_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-version", [], null, null, null, View_AppVersion_0, RenderType_AppVersion)), i0.ɵdid(1, 49152, null, 0, i2.AppVersion, [], null, null)], null, null); }
var AppVersionNgFactory = i0.ɵccf("app-version", i2.AppVersion, View_AppVersion_Host_0, {}, {}, []);
export { AppVersionNgFactory as AppVersionNgFactory };
