/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./overlay-lock.component";
import * as i3 from "../global-services/events.service";
var styles_OverlayLockComponent = [];
var RenderType_OverlayLockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OverlayLockComponent, data: {} });
export { RenderType_OverlayLockComponent as RenderType_OverlayLockComponent };
export function View_OverlayLockComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "overlay-lock"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "detail-active": 0, "menu-active": 1, "loading-active": 2, "navigating-active": 3 }), (_l()(), i0.ɵted(-1, null, ["\n    "])), i0.ɵncd(null, 0), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(7, 0, null, null, 7, "div", [["class", "panel-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "overlay-loader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "loader-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Loading..."])), (_l()(), i0.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "overlay-lock"; var currVal_1 = _ck(_v, 3, 0, _co.overlayActive, _co.menuActive, _co.loading, _co.navigating); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_OverlayLockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "overlay-lock", [], null, null, null, View_OverlayLockComponent_0, RenderType_OverlayLockComponent)), i0.ɵdid(1, 49152, null, 0, i2.OverlayLockComponent, [i3.EventsService], null, null)], null, null); }
var OverlayLockComponentNgFactory = i0.ɵccf("overlay-lock", i2.OverlayLockComponent, View_OverlayLockComponent_Host_0, {}, {}, ["*"]);
export { OverlayLockComponentNgFactory as OverlayLockComponentNgFactory };
