/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./downloads.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./downloads.component";
import * as i3 from "@angular/router";
import * as i4 from "../../global-services/user.service";
import * as i5 from "../../global-services/auth.service";
import * as i6 from "../../global-services/events.service";
var styles_DownloadsComponent = [i0.styles];
var RenderType_DownloadsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DownloadsComponent, data: {} });
export { RenderType_DownloadsComponent as RenderType_DownloadsComponent };
export function View_DownloadsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_DownloadsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "downloads", [], null, null, null, View_DownloadsComponent_0, RenderType_DownloadsComponent)), i1.ɵdid(1, 114688, null, 0, i2.DownloadsComponent, [i3.ActivatedRoute, i3.Router, i4.UserService, i5.AuthService, i6.EventsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DownloadsComponentNgFactory = i1.ɵccf("downloads", i2.DownloadsComponent, View_DownloadsComponent_Host_0, {}, {}, []);
export { DownloadsComponentNgFactory as DownloadsComponentNgFactory };
